// vendors
import React, { useMemo } from 'react';
import { defineMessage, FormattedNumber, useIntl } from 'react-intl';

const labels = {
  bodyLength: defineMessage({ defaultMessage: 'Longueur de la caisse' }),
  upperBoutWidth: defineMessage({
    defaultMessage: 'Largeur de la caisse aux épaules',
  }),
  waistWidth: defineMessage({
    defaultMessage: 'Largeur de la caisse à la taille',
  }),
  lowerBoutWidth: defineMessage({
    defaultMessage: 'Largeur de la caisse aux hanches (maximale)',
  }),
  bodyDepthAtHeel: defineMessage({
    defaultMessage: 'Profondeur de la caisse au talon',
  }),
  maximumBodyDepth: defineMessage({
    defaultMessage: 'Profondeur de la caisse maximale',
  }),
  neckWidthAtnutPS: defineMessage({
    defaultMessage: 'Largeur du manche au sillet de tête (série Prestige)',
  }),
  neckWidthAtNutSS: defineMessage({
    defaultMessage: 'Largeur du manche au sillet de tête (série Signature)',
  }),
  neckThicknessAtFirstFret: defineMessage({
    defaultMessage: 'Épaisseur manche à la 1ère frette',
  }),
  neckThicknessAtTenthFret: defineMessage({
    defaultMessage: 'Épaisseur manche à la 10ème frette',
  }),
  stringSpacingAtBridge: defineMessage({
    defaultMessage: 'Espacement des cordes au chevalet',
  }),
  scaleLengthPS: defineMessage({ defaultMessage: 'Diapason (série Prestige)' }),
  scaleLengthSS: defineMessage({
    defaultMessage: 'Diapason (série Signature)',
  }),
};

export default function Dimension({ data }) {
  const { value, name, label } = data;
  const { locale, formatMessage } = useIntl();

  const computedLabel = useMemo(() => {
    return name
      ? formatMessage(labels[name])
      : label[locale] || label?.fr || label?.en;
  }, [formatMessage, name, label, locale]);

  return (
    <>
      <b>{computedLabel} :</b>
      <br />
      <FormattedNumber
        value={value}
        style="unit"
        unit="millimeter"
        unitDisplay="narrow"
      />{' '}
      /{' '}
      <FormattedNumber
        value={(value * 0.03937007874).toPrecision(3)}
        style="unit"
        unit="inch"
        unitDisplay="narrow"
      />
    </>
  );
}
