// vendors
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

export default function Specification({ data }) {
  const { value, label } = data;
  const { locale } = useIntl();

  const computedLabel = useMemo(() => {
    return label[locale] || label?.fr || label?.en;
  }, [label, locale]);

  const computedValue = useMemo(() => {
    return value[locale] || value?.fr || value?.en;
  }, [value, locale]);

  return (
    <>
      <b>{computedLabel} :</b>
      <br />
      {computedValue}
    </>
  );
}
