import React from 'react';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { generateCustomGuitarUrl } from '../../lib/generateCustomGuitarUrl';
import getLocaleBlocks from '../../lib/getLocaleBlocks';
import BlockContent from '../components/BlockContent';
import GuitarTemplate from './Guitar';

function generateTranslations(langKey, slug) {
  return ['fr', 'en']
    .filter((locale) => locale !== langKey)
    .map((locale) => ({
      locale,
      url: generateCustomGuitarUrl(locale, slug),
    }));
}

export default function CustomGuitarTemplate({
  data: { sanityGuitarModel },
  pageContext,
}) {
  const { langKey } = pageContext;

  const data = React.useMemo(() => {
    const description = (
      <BlockContent blocks={getLocaleBlocks(sanityGuitarModel.body, langKey)} />
    );

    const featuredImage = {
      url: sanityGuitarModel.featuredImage.asset.url,
      gatsbyImageData:
        sanityGuitarModel.featuredImage.asset.localFile.childImageSharp,
    };

    const translations = generateTranslations(
      langKey,
      sanityGuitarModel.slug.current
    );

    const videos = (sanityGuitarModel.videos || []).map(({ url }) => url);

    const gallery = (sanityGuitarModel?.gallery?.images || []).map(
      ({ image }) => {
        const gatsbyImageData = getImage(image.asset.localFile.childImageSharp);

        return {
          gatsbyImageData,
        };
      }
    );

    return {
      name: sanityGuitarModel.name,
      featuredImage,
      description,
      gallery,
      translations,
      videos,
      dimensions: sanityGuitarModel.dimensions,
      startingPrice: sanityGuitarModel.startingAt,
      propseCustomGuitar: false,
      onDemandOnly: true,
    };
  }, [langKey, sanityGuitarModel]);

  return <GuitarTemplate data={data} />;
}

export const query = graphql`
  query CustomGuitarTemplate($id: String!) {
    sanityGuitarModel(id: { eq: $id }) {
      name
      startingAt
      slug {
        current
      }
      videos {
        url
        service
      }
      dimensions {
        ... on SanityCustomDimension {
          label {
            en
            fr
          }
          value
        }
        ... on SanityDimension {
          value
          name
        }
      }
      gallery {
        images {
          image {
            asset {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 760)
                }
              }
            }
          }
        }
      }
      featuredImage {
        asset {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1280)
            }
          }
        }
      }
      body {
        _rawEn(resolveReferences: { maxDepth: 10 })
        _rawFr(resolveReferences: { maxDepth: 10 })
      }
    }
  }
`;
