import React, { useCallback, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import {
  defineMessages,
  FormattedMessage,
  // FormattedNumber,
  useIntl,
} from 'react-intl';
// import Button from '../../components/Button/Button';
import BuySection from '../../components/BuySection';
import CTA from '../../components/CTA';
import Dimension from '../../components/Dimension/Dimension';
import Gallery from '../../components/Gallery';
import Heading from '../../components/Heading';
import Layout from '../../components/Layout';
// import { Crest } from '../../components/Logo';
import Sidebar from '../../components/Sidebar/Sidebar';
import Specification from '../../components/Specification/Specification';
import VideoGallery from '../../components/VideoGallery';
import BuyForm from '../../components/BuyForm';
import * as styles from './Guitar.module.css';
import useScrollThreshold from '../../hooks/useScrollThreshold';

const messages = defineMessages({
  gallery: { defaultMessage: 'Galerie' },
  galleryAncherHashtag: { defaultMessage: 'galerie' },
  videos: { defaultMessage: 'Vidéos' },
  videosAncherHashtag: { defaultMessage: 'videos' },
  specifications: { defaultMessage: 'Spécifications' },
  specificationsAncherHashtag: { defaultMessage: 'specifications' },
  purchase: { defaultMessage: 'Achat' },
  purchaseAncherHashtag: { defaultMessage: 'achat' },
});

export default function GuitarTemplate({ data }) {
  const { formatMessage } = useIntl();
  const mainSectionRef = useRef(null);
  const [asideIsVisible, setAsideIsVisible] = React.useState(false);
  const [openActionModal, setOpenActionModal] = useState(false);

  const isVisible = useScrollThreshold(140);

  const featuredImage = getImage(data.featuredImage.gatsbyImageData);

  const handleCloseModal = useCallback(() => setOpenActionModal(false), []);

  const handleOpenModal = useCallback(() => setOpenActionModal(true), []);

  const formSubject = useMemo(() => {
    return data.price
      ? formatMessage(
          { defaultMessage: 'Je suis intéressé par la guitare {name}' },
          { name: data.name }
        )
      : formatMessage(
          { defaultMessage: 'Je veux une guitare personnalisée - {name}' },
          { name: data.name }
        );
  }, [data.price, data.name, formatMessage]);

  React.useLayoutEffect(() => {
    const handleIntersect = (entries) => {
      entries.forEach((entry) => {
        if (asideIsVisible !== entry.isIntersecting) {
          setAsideIsVisible(entry.isIntersecting);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersect);

    observer.observe(mainSectionRef.current);
  }, [asideIsVisible, mainSectionRef]);

  return (
    <Layout
      translations={data.translations}
      logoOptions={{ inverted: true, fixed: true, isVisible }}
    >
      <article className="space-y-32 pt-[110px] md:pt-[170px]">
        <header className="-mb-8 container">
          <div className="w-full relative aspect-[5/7]">
            <GatsbyImage
              image={featuredImage}
              className="!absolute inset-0"
              layout="fill"
              role="presentation"
              alt=""
            />
          </div>

          <div>
            <h1
              className={classNames([
                'relative font-sans font-thin uppercase text-center mt-2',
                styles.title,
              ])}
            >
              {data.name}

              {data.subName && (
                <span
                  className={classNames([
                    'block font-serif text-[.5em] normal-case font-semibold italic',
                    styles.subTitle,
                  ])}
                >
                  {data.subName}
                </span>
              )}
            </h1>
          </div>
        </header>

        <main ref={mainSectionRef} className="space-y-32">
          {data.description && (
            <div className="max-w-prose mx-auto space-y-6 text-center">
              {React.cloneElement(data.description)}
            </div>
          )}

          {(data.gallery || []).length > 0 && (
            <section
              id={formatMessage(messages.galleryAncherHashtag)}
              className="scroll-mt-24 container"
            >
              <Heading level={2} className="sr-only">
                <FormattedMessage {...messages.gallery} />
              </Heading>

              <Gallery
                className="wide-content-contained"
                pictures={data.gallery}
              />
            </section>
          )}

          {((data.dimensions && Object.keys(data.dimensions).length > 0) ||
            (data.specifications &&
              Object.keys(data.specifications).length > 0)) && (
            <section
              id={formatMessage(messages.specificationsAncherHashtag)}
              className="scroll-mt-24 container bg-white py-container"
            >
              <Heading level={2} className="!mt-0">
                <FormattedMessage {...messages.specifications} />
              </Heading>

              {data.dimensions && Object.keys(data.dimensions).length > 0 && (
                <>
                  <Heading level={3}>
                    <FormattedMessage defaultMessage="Dimensions" />
                  </Heading>

                  <ul className="grid grid-cols-[repeat(auto-fill,minmax(200px,306px))] gap-4">
                    {data.dimensions.map((dimension) => (
                      <li className="break-inside-avoid">
                        <Dimension data={dimension} />
                      </li>
                    ))}
                  </ul>
                </>
              )}

              {data.specifications &&
                Object.keys(data.specifications).length > 0 && (
                  <>
                    <Heading level={3} className="border-b-yellow-800 border-b">
                      <FormattedMessage defaultMessage="Spécifications standards" />
                    </Heading>

                    <ul className="grid grid-cols-[repeat(auto-fill,minmax(100px,250px))] gap-8 justify-between">
                      {data.specifications.map((specification) => (
                        <li className="break-inside-avoid">
                          <Specification data={specification} />
                        </li>
                      ))}
                    </ul>
                  </>
                )}
            </section>
          )}

          {data.videos && data.videos.length > 0 && (
            <section
              id={formatMessage(messages.videosAncherHashtag)}
              className="scroll-mt-24 container"
            >
              <Heading level={2}>
                <FormattedMessage {...messages.videos} />
              </Heading>

              <VideoGallery
                videos={data.videos}
                className="wide-content-contained"
              />
            </section>
          )}

          {data.propseCustomGuitar && (
            <CTA>
              <div className="max-w-prose">
                <Heading level={2} className="!mt-0">
                  Vous préférez une guitare personnalisée?
                </Heading>

                <Link
                  to="/guitares-personnalisees"
                  className="underline font-medium text-xl"
                >
                  Découvrez le processus
                </Link>
              </div>
            </CTA>
          )}
        </main>

        <BuySection
          data={data}
          isVisible={asideIsVisible}
          onClick={handleOpenModal}
        />
      </article>

      <Sidebar open={openActionModal} onClose={handleCloseModal}>
        <div className="max-w-md">
          <Heading level={2}>
            <FormattedMessage defaultMessage="Acheter sur demande" />
          </Heading>

          <BuyForm
            requestType={data.price ? 'buy' : 'custom'}
            productName={data.name}
            subject={formSubject}
          />
        </div>
      </Sidebar>
    </Layout>
  );
}
